import { useEffect } from 'react';
import { Skeleton, Typography, Box, useMediaQuery, Button, Divider } from '@mui/material';
import { useNotify, useTranslate, useSafeSetState, useRedirect } from 'react-admin';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Head2 } from "../theme";
import { accessTokenDataProvider } from '../lib/data_provider';
import { ReactComponent as WerifyRejected } from '../assets/svg/x_werify_red.svg';
import { ReactComponent as WerifyApproved } from '../assets/svg/w_werify_green.svg';
import SelectWallet from '../components/select_wallet';
import CredentialsContainer from '../components/credentials_container';
import { Settings } from '../Settings';
import { LayoutAccessToken } from './layout_access_token';


const WerifyPointKiosk = () => {
  const [dataProvider, setDataProvider] = useSafeSetState();
  const { access_token, user_token } = useParams();
  const [resource, setResource] = useSafeSetState();
  const [attempt, setAttempt] = useSafeSetState();
  const [doneAttempt, setDoneAttempt] = useSafeSetState(null);
  const [wallet, setWallet] = useSafeSetState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const responseCode = queryParams.get('response_code');
  const notify = useNotify();
  const redirect = useRedirect();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const isLarge = window.screen.width >= 1920;

  useEffect(() => {
    const init = async () => {
      const provider = await accessTokenDataProvider(access_token);
      setDataProvider(provider);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dataProvider && create(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  useEffect(() => {
    let timeout;
    async function load(){
      if(!attempt) { return; }

      let {data} = await dataProvider.getOne('KioskAttempt', { id: attempt.id });
      if( data.state !== "PENDING" ) {
        if (data.redirectUrl) {
          return redirect(data.redirectUrl);
        }
        setDoneAttempt(data);
        setAttempt(null);
        data.timer && setTimeout(create, 4000, false);
      } else {
        setAttempt(data);
      }
    }
    timeout = setTimeout(load, 500);
    return function cleanup() { clearTimeout(timeout); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt]);

  const create = async (includeCode) => {
    if (!includeCode) {
      navigate({ pathname: location.pathname }, { replace: true });
    }
    try {
      const {data} = await dataProvider.create('KioskAttempt', { data: {
        input: {
          userToken: user_token,
          mobile: isMobile,
          responseCode: includeCode ? responseCode : null,
        }
      } });
      setDoneAttempt(null);
      setAttempt(data);
      setResource(data);
    } catch(e) {
      notify(e.toString(), { type: 'error' });
    }
  }
  
  const multiwallet = attempt?.multiwallet || doneAttempt?.multiwallet || false;
  const description = attempt?.description || doneAttempt?.description || false;
  const ruleName = attempt?.ruleName || doneAttempt?.ruleName || false;

  useEffect(() => {
    !wallet && setWallet(attempt?.wallet || doneAttempt?.wallet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt?.wallet, doneAttempt?.wallet]);

  const props = {
    description,
    ruleName,
    isMobile,
    multiwallet,
    wallet,
    setWallet,
    access_token,
    user_token,
    create,
    attempt,
    doneAttempt,
    isLarge,
  }

  return <LayoutAccessToken id="werify-point" resource={resource} flexDirection="column">
    { !doneAttempt && <PendingAttemptView {...props} /> }
    { doneAttempt && <DoneView {...props} /> }
  </LayoutAccessToken>
}


const makeVidconnectDeeplink = (attempt, access_token, user_token) => {
  const nonce = new Date().getTime();
  const { domain, redirectUriVerifier } = Settings.vidconnect;
  let state = user_token ? `${attempt.id}-${attempt.vidchainStateCode}-${access_token}-${user_token}` :  `${attempt.id}-${attempt.vidchainStateCode}-${access_token}`;
  return `${domain}/oauth2/auth?response_type=code&state=${state}&redirect_uri=${redirectUriVerifier}&client_id=${attempt.vidconnectClientId}&scope=openid%20${attempt.enabledScopes}&nonce=${nonce}`;
}

const PendingAttemptView = (props) => {
  let {description, ruleName, multiwallet, wallet, setWallet, isMobile, access_token, user_token, attempt, isLarge} = props;
  const [url, setUrl] = useSafeSetState(null);
  const [QRUrl, setQRUrl] = useSafeSetState(null);

  useEffect(() => {
    if (attempt) {
      let tempUrl = (wallet === "ALTME" || wallet === "TALAO") ? attempt.talaoUrl : makeVidconnectDeeplink(attempt, access_token, user_token);
      let tempQRUrl = (wallet === "ALTME" || wallet === "TALAO") ? attempt.openidUrlAltme : attempt.openidUrlVidchain;
      setUrl(tempUrl);
      setQRUrl(tempQRUrl);
    }
  }, [wallet, attempt?.vidchainUrl])

  
  if (isMobile) return <PendingAttemptViewMobile {...props} url={url} QRUrl={QRUrl} />
  
  const qrDimensions = isLarge ? "21em" : "17em";
  return <Box id="werify-point_kiosk" display="grid" gridTemplateColumns="repeat(3, 1fr)" gridTemplateRows="4em 1fr" gap="1em">
    <Box gridArea="1 / 2 / 2 / 3" display="flex" justifyContent="center" alignItems="flex-end">
      { description && ruleName &&
        <Box id="werify-point_title" display="flex" justifyContent="flex-end" flexDirection="column" textAlign="center">
          <Head2> { description }</Head2>
          <Typography marginTop="0.5em"> { ruleName } </Typography>
        </Box>
      }
    </Box>
    <Box gridArea="2 / 1 / 3 / 2" id="werify-point_kiosk_select-wallet" display="flex" justifyContent="flex-end" alignItems="center">
        {multiwallet && <SelectWallet value={wallet} setValue={setWallet} /> }
    </Box>
    <Box gridArea="2 / 2 / 3 / 3" id="werify-point_kiosk_main">
        { !QRUrl && <Skeleton id="loading_attempt" variant="rectangular" height={qrDimensions} width={qrDimensions} sx={{mb: "1.5em"}}/> }
        { QRUrl && <KioskQrCOde wallet={wallet} QRUrl={QRUrl} size={isLarge ? 325 : 276} /> }
    </Box>
  </Box>
}


const PendingAttemptViewMobile = ({description, ruleName, multiwallet, setWallet, wallet, url, QRUrl}) => {
  const translate = useTranslate();

  const handleOpenWallet = (url) => {
    wallet === "VIDWALLET" ? window.location.href = url : window.open(url, '_blank');
  };

  return <Box id="werify-point_kiosk" display="grid" alignItems="center" flexDirection="column" gap="1em">
    { description && ruleName &&
        <Box id="werify-point_title" display="flex" justifyContent="flex-end" flexDirection="column" textAlign="center">
          <Head2> { description }</Head2>
          <Typography marginTop="0.5em"> { ruleName } </Typography>
        </Box>
    }
    <Box id="mobile-open-wallet" display="flex" flexDirection="column">
      <Divider component="p" marginy=".5em">{translate("vc_validator.kiosk.open_on_phone")}</Divider> 
      <Button disabled={!url} variant="contained" fullWidth onClick={() => handleOpenWallet(url)}>{translate("vc_validator.kiosk.open_wallet")}</Button>
      <Divider component="p" margintop="1em">{translate("vc_validator.kiosk.or_scan_qr")}</Divider>
    </Box>

    <Box id="werify-point_kiosk_main" marginTop="1em" display="flex" justifyContent="center" alignItems="center">
      { !QRUrl && <Skeleton id="loading_attempt" variant="rectangular" height="21em" width="21em" sx={{mb: "1.5em"}} /> }
      { QRUrl && <KioskQrCOde wallet={wallet} QRUrl={QRUrl} size={325} /> }
    </Box>
    <Box id="werify-point_kiosk_select-wallet" display="flex" justifyContent="flex-end" alignItems="center">
        {multiwallet && <SelectWallet value={wallet} setValue={setWallet} /> }
    </Box>
  </Box>
}

const KioskQrCOde = ({wallet, QRUrl, size}) => { 
  const translate = useTranslate();
  const finalWallet = wallet === "ALTME" ? "TALAO" : wallet;
  return <Box id="current_attempt" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
    <QRCode
      size={size}
      data-vidchain-url={ wallet === "VIDWALLET" ? QRUrl : null }
      data-altme-url={ wallet === "ALTME" ? QRUrl : null }
      value={QRUrl}
      viewBox={`0 0 256 256`}
    />
    
    <Typography textAlign="center" marginTop=".5em">
      { translate("vc_validator.kiosk.instructions_1", { wallet: translate(`resources.Attempt.fields.wallets.${finalWallet}`) }) }
    </Typography>
  </Box>;
}


const DoneView = (props) => {
  let {description, ruleName, isMobile, create, doneAttempt} = props;
  if (isMobile) return <DoneViewMobile {...props} />

  return <Box id="werify-point_kiosk" display="grid" gridTemplateColumns="repeat(3, 1fr)" gridTemplateRows="4em 1fr" gap="1em">
    <Box gridArea="1 / 2 / 2 / 3" display="flex" justifyContent="center" alignItems="flex-end">
      { description && ruleName &&
        <Box id="werify-point_title" display="flex" justifyContent="flex-end" flexDirection="column" textAlign="center">
          <Head2> { description }</Head2>
          <Typography marginTop="0.5em"> { ruleName } </Typography>
        </Box>
      }
    </Box>
    <Box gridArea="2 / 2 / 3 / 3" id="werify-point_kiosk_main" minWidth="23em" justifySelf="center" alignSelf="flex-start">
        { doneAttempt && <DoneAttempt attempt={doneAttempt} updateAttempt={create} isMobile={false} /> }
    </Box>
    <Box gridArea="1 / 3 / 3 / 3">
      { doneAttempt?.state === "REJECTED" && <CredentialsContainer attempt={doneAttempt} onlyErrors={true} /> }
    </Box>
  </Box>
}

const DoneViewMobile = ({description, ruleName, create, doneAttempt}) => {
  return <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
    { description && ruleName &&
      <Box id="werify-point_title" display="flex" justifyContent="center" alignItems="center" flexDirection="column" textAlign="center" marginy="1em">
        <Head2> { description }</Head2>
        <Typography> { ruleName } </Typography>
      </Box>
    }
    <Box id="werify-point_kiosk" display="flex" justifyContent="center" flexDirection="column">
      <Box id="werify-point_kiosk_main" justifySelf="center" alignSelf="center">
        { doneAttempt && <DoneAttempt attempt={doneAttempt} updateAttempt={create} isMobile={true} /> }
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" maxWidth="30em" width="100%">
        { doneAttempt?.state === "REJECTED" && <CredentialsContainer attempt={doneAttempt} onlyErrors={true} /> }
      </Box>
     </Box>
 </Box>
}


const DoneAttempt = ({attempt, updateAttempt, isMobile}) => {
  const translate = useTranslate();
  const approved = isMobile ? "25em" : "14em";
  const rejected = isMobile ? "25em" : "13em";
  const failed = isMobile ? "16em" : "9em";
  const fullWidth = isMobile ? true : false;

  return <Box id="done_attempt" data-status={attempt.state} textAlign="center">
    <Box backgroundColor="#fafafa">
      { attempt.state === "APPROVED" &&
        <Box color="#00a975">
          <Box>
            <WerifyApproved style={{ height: approved, width: approved }} />
          </Box>
          <Head2>{ translate("vc_validator.kiosk.accepted") }</Head2>
        </Box>
      }
      { attempt.state === "REJECTED" &&
        <Box color="#c60042">
          <Box>
            <WerifyRejected style={{ height: rejected, width: rejected }} />
          </Box>
          <Head2>{ translate("vc_validator.kiosk.rejected") }</Head2>
        </Box>
      }
      { attempt.state === "FAILED" &&
        <Box color="#c60042">
          <ReportProblemIcon sx={{ height: failed, width: failed }} />
          <Head2>{ translate("vc_validator.kiosk.failed") }</Head2>
        </Box>
      }
      { !attempt.timer &&
        <Button sx={{my: isMobile ? "3em" : "1em"}} fullWidth={fullWidth} variant="contained" id="update-attempt-button" onClick={() => updateAttempt(false)}>{translate("vc_validator.kiosk.refresh")}</Button>
      }
    </Box>
  </Box>;
}

const KioskLogo = ({ logo, isMobile }) => 
  <img alt="logo" src={logo} style={{ height: isMobile ? "35px" : "58px", width: "auto" }} />

export {WerifyPointKiosk, KioskLogo};